// 机器人type sub_type 格式化
const typeFormat = function(index,addSelectNum){
	var nameList=[
		"buy_sell",
		"iceberg",
		"banzhuan",
		"wangge",
		"grid",
		// "wave",

		"shua",
		"budan_new",
		"hupan_buy",
		"hupan_sell",
		"push",
		"hugevol",//成交放量机器人
		
		"draw",
		"real_genpan",
		"kongpan",
		"real_genpan_quick",
		
		"shua_dex",
		"real_genpan_dex",
		"buy_sell_dex",
		"frontrun_dex",
		"snipe_dex",
		"banzhuan_dex",

		"shua_nft",
		"buy_sell_nft",
	];
	var type=nameList[index];
	var sub_type='';
	if(type=='hupan_buy'){
		// 买盘护盘
		type='hupan';
		sub_type='buy';
	}else if(type=='hupan_sell'){
		// 卖盘护盘
		type='hupan';
		sub_type='sell';
	}else if(type=='wangge'){
		// 马丁策略
		sub_type='10000';
	}
	// else if(type=='wave'){//'grid'
	// 	// 网格策略
	// 	if(addSelectNum){
	// 		sub_type=addSelectNum==1?'buy':'sell';
	// 	}else{
	// 		sub_type='buy';
	// 	}
	// }
	else if(type=='push'){
		// 趋势推动
		if(addSelectNum){
			sub_type=addSelectNum==1?'bids':'asks';
		}else{
			sub_type='bids';
		}
	}else if(type=='real_genpan'){
		// 跟盘机器人 standard-标准模式 rebalance-对冲模式
		if(addSelectNum){
			sub_type=addSelectNum==1?'standard':'rebalance';
		}else{
			sub_type='standard';
		}
	}
	return {type,sub_type}
};
export default typeFormat