<template>
    <div class="add_form">
        <!-- 1高抛低吸机器人 -->
        <div class="form follow_form" v-if="titleIndex==1">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>

            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.higherthan')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="red">{{$t('robot.sellout')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.below')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="green">{{$t('robot.purchase')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    {{$t('robot.second')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.per')}}</div>
                <div class="text wrap">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[4]">{{$t('robot.opponents')}}</el-checkbox>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maximum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit1_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minimum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[6]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit1_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.working')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[7]">{{$t('robot.chuck')}}</el-checkbox>
                </div>
            </div>
        </div>
        <!-- 2分批卖出机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==2">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.floorPrice')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval2')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[1]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />{{$t('robot.second')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityPerTime')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.frequency')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 3搬砖机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==3">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.targetExchange')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.currentCharge')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetCharge')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 4马丁策略量化机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==4">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.openingAmount')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit2_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.repTimes')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />{{$t('robot.ci')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interferenceRange')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.stopBackRatio')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.repDifferences')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addBackRatio')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.firstDouble')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[6]"></el-checkbox>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addMultiple')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[7]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />{{$t('robot.times')}}
                </div>
            </div>
        </div>
        <!-- 5网格策略量化机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==5">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.firstAmount')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit2_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.grids')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.stopRatio')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addInterval')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addIntervalIncrease')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />{{$t('robot.times')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addAmountIncrease')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />{{$t('robot.times')}}
                </div>
            </div>
        </div>

        <!-- 6成交量对敲机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==6">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.counterQuantity')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.perMinute')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" step-strictly />
                    <span class="company">{{$t('robot.ci')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 7盘口管理机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==7">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.orderPerNum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellPerNum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maintenance')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 8买盘护盘机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==8">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.protective')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gear')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 9卖盘护盘机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==9">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.protective')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gear')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 10趋势推动机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==10">
            <div class="item">
                <div class="switch">
                    <el-radio-group v-model="sub_type_select">
                        <el-radio-button label="1">{{$t('robot.buyBtn')}}</el-radio-button>
                        <el-radio-button label="2">{{$t('robot.sellBtn')}}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.plan')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" />
                    <span class="company" :class="sub_type_select==1?'green':'red'">{{sub_type_select==1?yenUnit2_Up:yenUnit1_Up}}</span>
                </div>
            </div>

            <!-- <div class="item">
                <div class="title">{{$t('robot.intervalRange')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[1]" :step="1" controls-position="right" :placeholder="$t('common.min')+' 1'" :min="1" class="inp" step-strictly />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="1" class="inp has_company" step-strictly />
                    <div class="company">{{$t('robot.random')}}</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perInterval')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div> -->
            <div class="item">
                <div class="title">{{$t('robot.endTime')}}</div>
                <div class="text">
                    <el-date-picker
                        :editable="false"
                        :clearable="false"
                        class="datetime inp"
                        v-model="paramArr[1]"
                        value-format="timestamp"
                        type="datetime"
                        :placeholder="$t('common.selectdate')"
                    />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.startPrice')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetPrice')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>
        <!-- 成交放量机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==11">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.tradingVolume')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="company">/{{$t('robot.minute')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.workingHours')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    <span class="company">{{$t('robot.minutes')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.splitBudget')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="company">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>

        <!-- 画线机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==12">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.upperLimit')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.lowerLimit')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" /> %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" /> %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.buy')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.offers')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.singleMax')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[6]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[7]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.swiping')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[8]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />{{$t('robot.individual')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.indexFollow')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[9]"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- 跟盘机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==13">
            <div class="item">
                <div class="switch">
                    <el-radio-group v-model="sub_type_select">
                        <el-radio-button label="1">{{$t('robot.standard')}}</el-radio-button>
                        <el-radio-button label="2">{{$t('robot.rebalance')}}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>

            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.targetDisk')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" /> %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" /> %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.buy')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.offers')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.singleMax')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[6]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.swiping')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[7]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />{{$t('robot.individual')}}
                </div>
            </div>
        </div>
        <!-- 控盘机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==14">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{yenUnit1_Up+$t('robot.budget')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{yenUnit2_Up+$t('robot.budget')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.target')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <!-- 小单 -->
            <div class="item">
                <div class="title">{{$t('robot.paySmall')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellSmall')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[6]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gears')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[7]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <!-- 中单 -->
            <div class="item">
                <div class="title">{{$t('robot.payMedium')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[8]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellMedium')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[9]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[10]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gears')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[11]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <!-- 大单 -->
            <div class="item">
                <div class="title">{{$t('robot.payLarge')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[12]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellLarge')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[13]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[14]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gears')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[15]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[16]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>

        </div>
        <!-- 主流币跟盘机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==15">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.targetDisk')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" /> %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" /> %
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.buy')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.offers')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.swiping')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[6]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />{{$t('robot.individual')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.hop')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[7]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
        </div>

        <!-- Dex画线机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==16">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transaction')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" />
                    <div class="company">{{$t('robot.individual')}}</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" step-strictly />
                    <div class="company">{{$t('robot.second')}}</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.direction')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[5]" :placeholder="$t('common.select')">
                        <el-option
                            :label="$t('robot.oscillation')"
                            value="0">
                        </el-option>
                        <el-option
                            :label="$t('robot.rise')"
                            value="1">
                        </el-option>
                        <el-option
                            :label="$t('robot.fall')"
                            value="2">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- Dex跟盘机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==17">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.targetDisk')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetPair')}}</div>
                <div class="doubletext">
                    <input v-model="paramArr[1]" type="text" :placeholder="$t('common.currency')" class="inp" />
                    <i class="split"></i>
                    <input v-model="paramArr[2]" type="text" :placeholder="$t('common.currency')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.scaling')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" />
                    <div class="company">%</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" step-strictly />
                    <div class="company">{{$t('robot.second')}}</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transactionMax')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" />
                    <div class="company">{{$t('robot.individual')}}</div>
                </div>
            </div>
        </div>
        <!-- Dex高抛低吸 -->
        <div class="form follow_form" v-else-if="titleIndex==18">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.higherthan')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="red company">{{$t('robot.sellout')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.below')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="green company">{{$t('robot.purchase')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    {{$t('robot.second')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.per')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit1_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maximum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit1_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minimum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    {{yenUnit1_Up}}
                </div>
            </div>
        </div>
        <!-- Dex抢买机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==19">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.trigger')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="red">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.everyPurchase')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="green">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.autoSell')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[2]"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- Dex狙击新盘 -->
        <div class="form follow_form" v-else-if="titleIndex==20">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.contract')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" :placeholder="$t('robot.contract')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.orderAmount')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="green">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.multiple')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="green">{{$t('robot.times')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.openingTime')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" :placeholder="$t('common.input')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.deduction')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[4]"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- DEX搬砖机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==21">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.targetExchange')}}(CEX)</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.currentCharge')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetCharge')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[2]" :step="0.1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />%
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                </div>
            </div>
        </div>

        <!-- NFT画线机器人 -->
        <div class="form follow_form" v-else-if="titleIndex==22">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1&&web!='opensea'">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            <div class="item dis" v-if="isDis==1&&web=='opensea'">
                <div class="title">{{$t('common.opensea')}}</div>
                <div class="select">
                    <el-input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.opensea')" readonly class="inp" />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transaction')}}</div>
                <div class="range">
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    <div class="line">-</div>
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" step-strictly />
                    <div class="company">{{$t('robot.individual')}}</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp has_company" step-strictly />
                    <div class="company">{{$t('robot.second')}}</div>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.direction')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[5]" :placeholder="$t('common.select')">
                        <el-option
                            :label="$t('robot.oscillation')"
                            value="0">
                        </el-option>
                        <el-option
                            :label="$t('robot.rise')"
                            value="1">
                        </el-option>
                        <el-option
                            :label="$t('robot.fall')"
                            value="2">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- NFT高抛低吸 -->
        <div class="form follow_form" v-else-if="titleIndex==23">
            <div class="item dis" v-if="isDis==1">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item dis" v-if="isDis==1&&web!='opensea'">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                    <i class="split"></i>
                    <input v-model="yenUnit2_Up" type="text" :placeholder="$t('common.currency')" class="inp" readonly />
                </div>
            </div>
            <div class="item dis" v-if="isDis==1&&web=='opensea'">
                <div class="title">{{$t('common.opensea')}}</div>
                <div class="select">
                    <el-input v-model="yenUnit1_Up" type="text" :placeholder="$t('common.opensea')" readonly class="inp" />
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.higherthan')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[0]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="red company">{{$t('robot.sellout')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.below')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[1]" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" />
                    <span class="green company">{{$t('robot.purchase')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input-number v-model="paramArr[2]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    {{$t('robot.second')}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.per')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[3]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    {{yenUnit1_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maximum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[4]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    {{yenUnit1_Up}}
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minimum')}}</div>
                <div class="text">
                    <el-input-number v-model="paramArr[5]" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp" step-strictly />
                    {{yenUnit1_Up}}
                </div>
            </div>
        </div>
        
        <!-- 添加 -->
        <div class="foot" v-if="!isEdit">
            <div class="btn" @click="cancel">{{$t('common.back')}}</div>
            <div class="btn submit" @click="confirm('run')">{{$t('common.saveRun')}}</div>
            <div class="btn submit" @click="confirm">{{$t('common.add')}}</div>
        </div>
        <!-- 编辑 -->
        <div class="foot" v-else-if="!!isEdit">
            <div class="btn" @click="cancel">{{$t('common.back')}}</div>
            <div v-if="state==0" class="btn submit" @click="confirm('run')">{{$t('common.saveRun')}}</div>
            <div class="btn submit" @click="confirm">{{$t('common.save')}}</div>
        </div>
    </div>
</template>
<script>
import { getRobot } from '@/api/robot';
export default {
    props:[
        'handleCancel',
        'handleConfirm',
        'titleIndex',
        'openPop',
        'yenUnit1',
        'yenUnit2',
        'isEdit',
        'state',
        // 手动操盘的数据
        'exchange',
        'isDis',
        'web',
    ],
    data(){
        return{
            paramArr:[],//表单数据
            targetExchange:'',
            
            sub_type_select:'1',
        }
    },
    computed:{
        yenUnit1_Up(){
            return this.yenUnit1.toUpperCase()
        },
        yenUnit2_Up(){
            return this.yenUnit2.toUpperCase()
        },
    },
    methods:{
        // 传入param数据格式化
        paramInit(param,sub_type_num){
            if(sub_type_num==2){
                this.sub_type_select=sub_type_num
            }
            // console.log(this.titleIndex+'-'+param)
            if(this.titleIndex==4&&!param){
                // 马丁默认值
                param='20 5 0.013 0.003 0.03 0.003 0 2'
            }else if(this.titleIndex==5&&!param){
                // 网格默认值
                param='20 30 0.01 0.01 1 1'
            }

            this.paramArr=param?param.split(' '):[];

            // 特殊类型判定处理
            if(this.titleIndex==1){
                // 有选项框的类型 给默认值
                this.paramArr[4]=this.paramArr[4]?this.paramArr[4]:'0';
                this.paramArr[7]=this.paramArr[7]?this.paramArr[7]:'0';
            }else if(this.titleIndex==4){
                // 有选项框的类型 给默认值
                this.paramArr[6]=this.paramArr[6]?this.paramArr[6]:'0';
            }else if(this.titleIndex==5){
                // 后续默认参数（高级选项） 0 0 15m 0.1 0 0 0
                this.paramArr[6]='0';
                this.paramArr[7]='0';
                this.paramArr[8]='15m';
                this.paramArr[9]='0.1';
                this.paramArr[10]='0';
                this.paramArr[11]='0';
                this.paramArr[12]='0';
            }else if(this.titleIndex==12){
                this.paramArr[9]=this.paramArr[9]?this.paramArr[9]:'0';
            }else if(this.titleIndex==19){
                // 有选项框的类型 给默认值
                this.paramArr[2]=this.paramArr[2]?this.paramArr[2]:'0';
            }else if(this.titleIndex==20){
                // 有选项框的类型 给默认值
                this.paramArr[4]=this.paramArr[4]?this.paramArr[4]:'0';
            }
            
            // 交易所
            if(
                this.titleIndex==3||
                this.titleIndex==13||
                this.titleIndex==15||
                this.titleIndex==17||
                this.titleIndex==21
            ){
                this.targetExchange=this.paramArr[0];
            }
        },
        // 关闭弹窗时初始化
        init(){
			this.$nextTick(() => {
                this.paramArr=[];
                this.targetExchange= '';
                this.sub_type_select='1';
			})
        },
        cancel(){
            this.$emit('handleCancel');
        },
        confirm(run){
            // console.log('son-confirm',this.paramArr)
            var sub_type_select;
            
            if(this.titleIndex==10||this.titleIndex==13){//this.titleIndex==5||
                // 切换按钮的sub_type
                sub_type_select=this.sub_type_select
            }
            
            // 非空判定
            var empty=false;
            var inputList=document.querySelectorAll('.add_form .el-input__inner')
            try{
                inputList.forEach(item=>{
                    if(!item.value){
                        throw('dataErr')
                    }
                })
            }catch{
                empty=true;
                this.$message({
                    message: this.$t('tip.dataErr'),
                    type: 'error',
                    center: true,
                    offset: 100,
                    customClass: 'shotMsg',
                });
            }
            // 非空
            if(!empty){
                // console.log('paramArr',this.paramArr)
                var data=this.paramArr.join(' ')
                /**
                 * sub_type_select 切换按钮的sub_type
                 * run  是否启动
                 */
                var isRun;
                if(run=='run'||(!!this.isEdit&&this.state==1)){
                    isRun=1
                }else{
                    isRun=0
                }
                // 确定
                this.$emit('handleConfirm',data,sub_type_select,isRun)
            }
        },
	
        // 交易所
        openPopSon(index){
            this.$emit('openPop',{
                web:this.paramArr[index],
                num:index
            })
        },
        handleSelect(data,num){
            this.targetExchange = data.text;
            this.paramArr[num] = data.web;
        },

        // 获取机器人参数 - sub_type切换
        getRobot(data){
            getRobot(data).then(res=>{
                if(!!res){
                    this.$nextTick(()=>{
                        this.paramInit(res.param);
                    });
                }else{
                    this.$nextTick(()=>{
                        this.paramInit('');
                    });
                }
            })
        },
    },
}
</script>

<style scoped="scoped" lang="stylus">
	@import '~@/views/robot/robot.styl';
	@import '~@/views/robot/add.styl';
</style>
<style lang="stylus" scoped>
@import '~@/assets/style/var.styl';

.add_form{
    .red{
        color $red
    }
    .green{
        color $green
    }
    // 跟盘dex
    .follow_form{
        width 400px
        .item{
            .text{
                flex none
                .inp{
                    width 217px
                    //margin 0 8px
                }
            }
            .select {
                max-width calc( 100% - 100px - 8px)
                .inp {
                    border none
                    padding 0
                    margin 0
                }
            }
            .doubletext{
                width calc( 100% - 100px - 8px )
                .inp{
                    max-width calc(50% - 8.5px)
                    margin 0
                }
            }
        }
    }
    .item .switch{
        margin auto
    }
    // 禁用
    .dis{
        opacity 0.4
        /deep/ .inp{
            cursor not-allowed
            input{
                cursor not-allowed
            }
        }
    }
}
</style>