import {http,http_no_aes} from '@/utils/http'

// 机器人相关
// 2.4获取账户机器人参数信息接口（获取已设置的机器人参数）
export const getRobot = data => http('/Markets/get_robot_param',data,'post')
// 2.5设置机器人参数
export const setRobot = data => http_no_aes('/Markets/set_robot_param',data,'post')
// 2.6获取机器人列表接口
export const getRobotList = data => http('/Markets/get_robot_list',data,'post')
// 2.10删除机器人列表接口
export const delRobot = data => http('/Markets/del_robot_param',data,'post')

// 系统相关
// 2.4获取账户日志记录接口
export const getLog = data => http_no_aes('/System/get_log_for_user',data,'post')
